<template>
  <v-select
    id="managingUser"
    v-model="currentMembersUserID"
    label="title"
    :options="usersOptions"
    :value="getItem(item)"
    name="managingUser"
    :disabled="readonly"
  >
  </v-select>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    usersOptions: {
      type: Array,
      default: () => [],
    },
      allUsersOptions: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      currentMembersUserID: "",
    }
  },
  watch: {
    currentMembersUserID() {
      this.getUserData()
    },
  },
  methods: {
    getUserData() {
      this.$emit("changeUser", this.currentMembersUserID, this.index);
    },
    getItem(val) {
      if (val) {
        this.currentMembersUserID = this.allUsersOptions.filter(i => i.value === val)[0];
      }
    }
  }
}
</script>

<style scoped>

</style>