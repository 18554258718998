<template>
  <div>
    <h1>Maintain User Members</h1>
    <br/>
    <b-row>
      <b-col md="6">
        <div>
          <b-form-group>
            <Label class="title-label">Managing User</Label>
            <v-select
              id="managingUser"
              v-model="ManagingUser"
              label="title"
              :options="usersOptions"
              name="managingUser"
            >
            </v-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col>
        <b-form-group>
          <Label class="title-label">Assigned Locations</Label>
          <div class="members-location">{{ membersUserLocations }}</div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <b-form-group>
          <Label class="title-label">Location</Label>
          <v-select
            id="state"
            v-model="location"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="StatesList"
            placeholder=""
            name="state"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-row v-if="readonly">
          <div class="edit-btn-block">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="m-1"
              :disabled="!ManagingUser || !location"
              @click.prevent="editScreen()"
            >
              Edit
            </b-button>
          </div>
        </b-row>
        <b-row v-else>
          <div class="edit-btn-block">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="m-1"
              :disabled="isDisabledSaveButton"
              @click.prevent="saveScreen()"
            >
              Save
            </b-button>
          </div>
          <div class="edit-btn-block">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="m-1"
              @click.prevent="cancelScreen()"
            >
              Cancel
            </b-button>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <div
      v-for="(item, index) in membersUserIDs"
      :key="index">
      <b-row v-if="item !== null" class="mt-1">
        <b-col md="1">
          <div v-if="index > 0 && !readonly" class="arrow-icon" @click="moveUserUp(index)">
            <feather-icon
              icon="ArrowUpIcon"
              size="21"
            />
          </div>
          <div v-if="index < membersUserIDs.length - 1 && !readonly" class="arrow-icon" @click="moveUserDown(index)">
            <feather-icon
              icon="ArrowDownIcon"
              size="21"
            />
          </div>
        </b-col>
        <b-col md="6">
          <MembersSelect
            :item="item"
            :index="index"
            :readonly="readonly"
            :usersOptions="allUsersOptions"
            :allUsersOptions="oldAllUsersOptions"
            @changeUser="changeUser"
          />
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col>
        <div class="edit-btn-block">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="m-1"
            @click.prevent="addUser()"
          >
            Add User
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BButton, BCol, BFormGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import APIService from "@core/utils/APIService";
import Ripple from "vue-ripple-directive";
import MembersSelect from "@/views/admin/components/MembersSelect";

const apiService = new APIService();

export default {
  components: {
    MembersSelect,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      readonly: true,
      ManagingUser: "",
      membersUserLocations: "",
      clientSearch: "",
      location: "",
      usersOptions: [],
      allUsersOptions: [],
      oldAllUsersOptions: [],
      membersUserIDs: [],
      membersUsers: [],
      StatesList: [
        'ALL', 'CBCS', 'BSMI', 'EDR East', 'EDR West', 'Med Cnvs', 'PreSurv', 'NYSIF',
        'NY - Bronx', 'NY - Brooklyn', 'NY - Manhattan', 'NY - Queens', 'NY - Staten',
        'SIU Northeast', 'SIU Southeast', 'SIU Central', 'SIU West', 'AK', 'AL',
        'AR', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'HI', 'IA',
        'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN',
        'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY',
        'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA',
        'VT', 'WA', 'WI', 'WV', 'WY', 'ZZ'
      ],
      isDisabledSaveButton: true,
    }
  },
  watch: {
    membersUserIDs(val, oldVal) {
      if ((val && val[0]) || (!val && oldVal)) {
        this.isDisabledSaveButton = false
      }
    },
    ManagingUser(val) {
      this.location = "";
      this.isDisabledSaveButton = true;
      this.membersUserIDs = [];
      if (val) {
        this.getManagerMembers(val)
      } else {
        this.membersUserLocations = "";
      }
    },
    location() {
      this.membersUserIDs = [];
      this.isDisabledSaveButton = true;
      this.membersUsers.map(item => {
        if (item.location === this.location) {
          this.membersUserIDs = item.userMemberIDs
        }
      })
    }
  },
  async created() {
    try {
      await this.getUsers();
      await this.getAllUsers();
    } catch (err) {
      this.error = err
    }
  },
  methods: {
    async getUsers() {
      const response = await apiService.get('users/User').then(res => res.data).catch(error => error)
      if (response) {
        this.usersOptions = response.Users.map(d => ({
          title: d.UserAccountID + " - " + d.LastName + ", " + d.FirstName + " - " + d.EMail,
          value: d.UserAccountID
        }));
      }
    },
    async getAllUsers() {
      this.oldAllUsersOptions = [];
      const response = await apiService.get('users/User,Investigator?max=10000"').then(res => res.data).catch(error => error)
      if (response) {
        this.allUsersOptions = response.Users.map(d => ({
          title: d.UserAccountID + " - " + d.LastName + ", " + d.FirstName + " - " + d.EMail,
          value: d.UserAccountID
        }));
        this.allUsersOptions.map(item => {
          this.oldAllUsersOptions.push(item)
        })

      }
    },
    editScreen() {
      this.readonly = false;
      if (!this.membersUserIDs || !this.membersUserIDs.length) {
        this.isDisabledSaveButton = true
      }
    },
    saveScreen() {
      const userMemberIDs = this.membersUserIDs.filter(i => i !== null)
      const postData = {
        "userManagerID": this.ManagingUser.value,
        "userMemberIDs": userMemberIDs,
        "location": this.location,
      }
      apiService.post('user-team-member', postData).then(res => {
        this.cancelScreen();
      })
    },
    cancelScreen() {
      this.getManagerMembers(this.ManagingUser)
      this.readonly = true;
    },
    getManagerMembers(val) {
      this.membersUserLocations = "";
      apiService.get('user-team-member/' + val.value).then(res => {
        this.membersUsers = res.data;
        this.membersUsers.map(item => {
          this.membersUserLocations = this.membersUserLocations + item.location + ", "
        })
        this.membersUserLocations = this.membersUserLocations.substring(0, this.membersUserLocations.length - 2)
        if (this.location) {
          this.membersUserIDs = [];
          this.membersUsers.map(item => {
            if (item.location === this.location) {
              this.membersUserIDs = item.userMemberIDs
            }
          })
        } else {
          this.membersUserIDs = [];
        }
      })
    },
    getMembersUser(userID) {
      return this.usersOptions.filter(i => i.value === userID)[0];
    },
    addUser() {
      this.membersUserIDs.push(0)
    },
    changeUser(user, index) {
      this.membersUserIDs.splice(index, 1, user ? user.value : null)
      this.allUsersOptions = this.oldAllUsersOptions.filter(i => this.membersUserIDs.indexOf(i.value) === -1)
    },
    moveUserUp(index) {
      if (this.membersUserIDs[index])
      this.membersUserIDs.splice(index - 1, 2, this.membersUserIDs[index], this.membersUserIDs[index - 1]);
    },
    moveUserDown(index) {
      if (this.membersUserIDs[index + 1])
      this.membersUserIDs.splice(index, 2, this.membersUserIDs[index + 1], this.membersUserIDs[index]);
    }
  }
}
</script>

<style scoped>
.breadcrumb-position {
  padding-left: 0;
}
.title-label {
  font-size: 1.5rem;
}
.edit-btn-block {
  margin-top: 1.2rem;
}
.arrow-icon {
  display: table-cell;
  border: 2px solid #c7c7cc;
  margin: 0.2rem;
  cursor: pointer;
}
.members-location {
  padding: 0.33rem;
  border-radius: 0.3rem;
  height: 2.4rem;
  font-weight: bold;
  font-size: 1.35rem
}
</style>